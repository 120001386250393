export const gtmFbqScript = `function gtmFbqScript() {
    if (!window.fbq) {
        window.fbq = (function() {
            var pendingCalls = []
            function tmpFbq(command) {
                if (command === 'getPendingCalls') {
                    return pendingCalls
                }
                pendingCalls.push(arguments)
            }
            tmpFbq.isMaltTmpFbq = true
            return tmpFbq
        })()
    }
}`;

export const gtmDataLayerScript = `function gtmDataLayerScript(googleTagManagerId, dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayer);
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', googleTagManagerId);
}`;
